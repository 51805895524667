<template>
  <div class="triggers-and-actions">
    <div class="cell" :class="{ 'disabled': disableTrigger }">
      <span class="badge">Trigger</span>
      <strong>Every time this happens...</strong>
      <span class="description">The Ethereum (ETH) balance in my wallet increases or decreases</span>
    </div>
    <div class="cell" :class="{ 'disabled': disableAction }">
      <span class="badge">Action</span>
      <strong>Dispatch does this for you:</strong>
      <span class="description">Send a message in Telegram alerting me of the change</span>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      disableTrigger: {
        type: Boolean,
        default: false,
      },
      disableAction: {
        type: Boolean,
        default: false,
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .triggers-and-actions
    @apply mb-4
    @apply w-full

    @apply flex
    @apply justify-between

  .cell
    @apply flex
    @apply flex-col
    @apply items-start

    width: calc(50% - .75rem)

    &.disabled
      @apply opacity-25

    strong
      @apply mt-2
      @apply mb-1

  .description
    @apply text-sm
    @apply text-gray-800

  .badge
    @apply p-1
    @apply text-xs
    @apply rounded-sm
    @apply bg-gray-300
    @apply text-gray-800

</style>
