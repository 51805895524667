<template>

  <div class="step-container">

    <template v-if="currentStep === 1">

      <h4 class="title text-primary-500">Welcome to Dispatch!</h4>
      <h6 class="subtitle">Automate using blockchain events and data</h6>

      <img class="step-1-image" src="@/assets/images/welcome-modal/step-1.png" />

      <p class="description mt-6 text-center">
        Dispatch is the simplest way to create blockchain-based workflows —
        whether you're setting up personal notifications or creating powerful,
        time-saving automations.
        <br>
        <br>
        Let's walk through an example to get started.
      </p>

      <div class="buttons">
        <button class="small" @click="nextStep()">Take me on the tour!</button>
      </div>

    </template>

    <template v-if="currentStep === 2">

      <h4 class="title text-primary-500">How Dispatch works</h4>
      <h6 class="subtitle">With Dispatch, you create Patches</h6>

      <WelcomeModalTriggerAndActions />

      <img class="image" src="@/assets/images/welcome-modal/step-2.png" />

      <p class="description">
        A Patch is an automated workflow consisting of a Trigger event and a
        resulting Action you want to happen every time your Trigger conditions
        are met.
      </p>

    </template>

    <template v-if="currentStep === 3">

      <h4 class="title">Triggers</h4>

      <p class="description large">
        A Trigger is an event you want to automatically start another process or
        result in some other action.
      </p>

      <WelcomeModalTriggerAndActions :disable-action="true" />

      <img class="image" src="@/assets/images/welcome-modal/step-3.png" />

      <p class="description">
        The Trigger sets the conditions that need to be met for your Patch to
        run.
      </p>

    </template>

    <template v-if="currentStep === 4">

      <h4 class="title">Actions</h4>

      <p class="description large">
        An Action is what you want to happen automatically every time your
        Trigger conditions are met.
      </p>

      <WelcomeModalTriggerAndActions :disable-trigger="true" />

      <img class="image" src="@/assets/images/welcome-modal/step-4.png" />

      <p class="description">
        Actions can include tasks in external services (i.e. sending email or
        Telegram messages).
      </p>

    </template>

    <template v-if="currentStep === 5">

      <h4 class="title">We want your feedback!</h4>

      <img class="step-5-image" src="@/assets/images/welcome-modal/step-5.png" />

      <p class="description large">
        This is our first beta release and we're working on adding more features
        that will make Dispatch more helpful for you.
        <br>
        <br>
        Have feedback or suggestions? Let us know by clicking the Help widget in
        the lower right corner of any screen.
      </p>

    </template>

    <div class="buttons" v-if="currentStep !== 1">
      <button class="small tertiary" @click="previousStep()">Back</button>
      <button class="small" @click="nextStep()">{{ currentStep === steps.length ? 'Create your first Patch!' : 'Next' }}</button>
    </div>

  </div>

  <footer>
    <span
      :key="stepNumber"
      @click="goToStep(stepNumber)"
      class="current-step-indicator"
      v-for="stepNumber in steps.length"
      :class="{ 'active': stepNumber === currentStep }"
    />
  </footer>

</template>

<script>

  import { mapState } from 'vuex'

  import step1Image from '@/assets/images/welcome-modal/step-1.png'
  import step2Image from '@/assets/images/welcome-modal/step-2.png'
  import step3Image from '@/assets/images/welcome-modal/step-3.png'
  import step4Image from '@/assets/images/welcome-modal/step-4.png'
  import step5Image from '@/assets/images/welcome-modal/step-5.png'

  import EventBus from '@/components/utils/EventBus.vue'

  import WelcomeModalTriggerAndActions from '@/components/etc/WelcomeModalTriggerAndActions.vue'

  export default {
    inject: ['$mixpanel'],
    components: {
      WelcomeModalTriggerAndActions,
    },
    computed: {
      ...mapState('modals', ['isModalOpen']),
    },
    watch: {
      // when the modal is closed, log a mixpanel event to track which screens
      //  they saw
      isModalOpen(newValue) {

        if (newValue) return

        const steps = {}
        const numStepsTotal = this.steps.length

        let numStepsSeen = 0

        this.steps.forEach((step) => {
          steps[step.name] = step.seen
          if (step.seen) numStepsSeen += 1
        })

        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('Welcome Modal Dismissed', {
            sawAllSteps: numStepsSeen === numStepsTotal,
            numStepsTotal,
            numStepsSeen,
            steps,
          })
        })

      },
    },
    data() {
      return {
        currentStep: 1,
        imagesToPreload: [
          step1Image,
          step2Image,
          step3Image,
          step4Image,
          step5Image,
        ],
        steps: [
          { seen: false, name: 'Welcome To Dispatch' },
          { seen: false, name: 'How Dispatch Works' },
          { seen: false, name: 'Triggers' },
          { seen: false, name: 'Actions' },
          { seen: false, name: 'We Want Your Feedback' },
        ],
      }
    },
    created() {

      // this seems redundant, but it's necessary to log the Mixpanel event for
      //  step 1
      this.goToStep(1)

      this.imagesToPreload.forEach((imageSrc) => {
        const image = new Image()
        image.src = imageSrc
      })

    },
    methods: {
      goToStep(stepNumber) {

        EventBus.$emit('modal:scroll-to-top')

        this.currentStep = Math.max(1, Math.min(this.steps.length, stepNumber))

        const step = this.steps[this.currentStep - 1]

        step.seen = true

        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track(`Welcome Modal Screen Viewed - ${step.name}`, {
            stepNumber: this.currentStep,
            stepName: step.name,
          })
        })

      },
      previousStep() {
        this.goToStep(this.currentStep - 1)
      },
      nextStep() {
        if (this.currentStep + 1 > this.steps.length) {
          this.$store.dispatch('modals/CLOSE_MODAL')
          return
        }
        this.goToStep(this.currentStep + 1)
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .step-container
    @apply flex
    @apply flex-col
    @apply items-center
    @apply justify-center

  .title
    @apply mb-2
    @apply text-center

  .subtitle
    @apply mb-6
    @apply text-center
    @apply text-gray-1000

  .description
    @apply text-gray-800

    &.large
      @apply mt-2
      @apply mb-6
      @apply text-lg

      +breakpoint(sm)
        @apply mt-4
        @apply mb-6
        @apply text-xl

  .step-1-image
    @apply w-full

    max-width: theme('width.56')

  .image
    @apply w-full

    max-width: theme('width.96')

  .step-5-image
    @apply mt-4
    @apply w-full

    max-width: theme('width.40')

  footer
    @apply mt-6
    @apply space-x-4

    @apply flex
    @apply justify-center

  .buttons
    @apply mt-6
    @apply flex
    @apply space-x-4

    +breakpoint(sm)
      @apply mt-8

  .current-step-indicator
    @apply w-2
    @apply h-2
    @apply bg-gray-400
    @apply inline-block
    @apply rounded-full
    @apply shadow-inner
    @apply cursor-pointer

    &.active
      @apply bg-primary-500

</style>
